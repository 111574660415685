body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


body {
  /* padding: 25px; */
  background-color: white;
  color: black;
  font-size: 25px;
  /* width: 100vw; */
}

.dark-mode {
  background-color: black;
  color: white;
}


/* .dark-mode a {
  color: white !important;
} */

.dark-mode .backgroundImage {
  background-color: #2e2e2e;
  color: white !important;
}

.dark-mode .chatmain {
  background-color : #262626;;
}


.dark-mode .welcome-para {
color: white !important;
}

.dark-mode .welcome-head {
  color: white !important;
}

.dark-mode .first-search-div {
  color: white !important;
  background: #333 !important;
}
.dark-mode #basic-addon1 {
  color: white !important;
  background: #333 !important;
}







/* // "proxy": "http://10.5.48.61:9096/", */

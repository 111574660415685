
.mainDivLogin {
    height: 100vh;
    background-color: #86b7fe;
    display: flex;
    align-items: center;
    padding: 0px 20px;
}

.iamgeLogin {
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.iamgeLogin img {
    position: relative;
    left: 10%;
}

.rightPartLogin {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 75%;
    height: 95vh;
    background-color: white;
    border-radius: 20px;
}

.innerRightPartLogin {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.welcome-head {
    color: #495057;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sign-para {
    color: #797c8c !important;
    font-size: 14px;
}

.handleArea {
    height: 45px;
}

.formSection {
    width: 30vw;
}

.rightPartLogin {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}

.Toastify__toast--error {
    z-index:100000;
    /* left:-500px; */
    /* top:100px; */
}
.loginBtn{
   background: rgb(81 152 255) ;
   border: none;
   width: 100%;
}

.brandLogo img{
    height: 70px !important;
    margin:12px !important ;

}

@media only screen and (max-width: 990px) {
    .iamgeLogin img {
        display: none;
    }
    .rightPartLogin {
        width: 100%;
    }
.formSection{
    padding: 10px !important;
}
    .mainDivLogin {
        flex-direction: column;
        padding: 18px;
    }

    .iamgeLogin {
        width: fit-content;
        height: fit-content;
    }

    .innerFields {
        padding: 13px;
    }

    .handleArea {
        width: 100% !important;
    }

    .formSection {
        width: 100%;
    }
}

.loco_icon {
    width: 50%;
    height: auto;
}

@media only screen and (max-width: 280px) {
    .iamgeLogin img {
        display: none;
    }

    .rightPartLogin {
        width: 100%;
    }
    .formSection{
        padding: 10px !important;
    }
    .mainDivLogin {
        flex-direction: column;
        padding: 18px;
    }

    .iamgeLogin {
        width: fit-content;
        height: fit-content;
    }

    .innerFields {
        padding: 1px !important
    }

    .handleArea {
        width: 100% !important;
    }

    .sign-para {
        font-size: 9px;
    }

}

@media only screen and (max-height: 655px) {
    .mainDivLogin,
    .rightPartLogin{
        height: auto;
    }
    .formSection{
        padding: 10px !important;
    }
}
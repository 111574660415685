
.all-in-one{
    display: flex;
    flex-direction: row;
    height: 100vh;
}

@media only screen and (max-width: 990px) {

    .all-in-one{
        display: flex;
    }

}

.chatmain {
    width: 27%;
}

.logo-search {
    position: relative;
    width: 100%;
    height: 100px;
}

.company-logo {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100px;
    mix-blend-mode: hard-light;

}

.company-logo img {
    width: 70%;
    height: 100%;
    object-fit: contain;
}


.seacrh-bar input {
    max-width: 100%;
    height: auto;
}

.seacrh-bar :placeholder-shown {
    font-size: 15px;
}

.form-control:focus {
    box-shadow: none !important;
    border-color: lightgray !important;
}

.chat-message-list {
    padding: 15px;
    overflow-x: hidden;
    overflow-y: scroll;
    height: 85vh;
    transition: 0.5s;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.chat-message-list::-webkit-scrollbar {
    display: none;

}

.profilePicture {
    display: flex;
    justify-content: center;
    -webkit-align-items: center;
    border-radius: 50%;
    width: 2.5vw;
    height: 2.5vw;
    font-size: 16px;
    padding: 2px;
    margin-right: 0.5vw;
    font-weight: 500;
    background-color: yellow;
}


.profilePicture2 {
    display: flex;
    justify-content: center;
    -webkit-align-items: center;
    border-radius: 50%;
    width: 2.5vw;
    height: 2.5vw;
    font-size: 16px;
    padding: 2px;
    margin-right: 0.5vw;
    font-weight: 500;
    background-color: green;
}

.profilePicture3 {
    display: flex;
    justify-content: center;
    -webkit-align-items: center;
    border-radius: 50%;
    width: 2.5vw;
    height: 2.5vw;
    font-size: 16px;
    padding: 2px;
    margin-right: 0.5vw;
    font-weight: 500;
    background-color: red;
}

.lastMessage {
    max-width: 72%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.lastMessage {
    padding-left: 3.8vw !important;
    font-size: 14px !important;
    opacity: 0.9;
    /* max-width: 72%; */
}


.time_lastMessage {
    display: flex;
    justify-content: space-between;
    font-weight: 300;
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
}

.time {
    font-size: 12px;
}

.loader-container {
    width: 100%;
    height: 100vh;
    position: fixed;
    background: rgba(0, 0, 0, 0.834)
        url("https://media.giphy.com/media/8agqybiK5LW8qrG3vJ/giphy.gif") center
        no-repeat;
    z-index: 1;
}

@media only screen and (max-width: 1280px) {
    .profilePicture {
        width: 3vw;
        height: 3vw;
        font-size: 14px;
    }

    .profilePicture2 {
        width: 3vw;
        height: 3vw;
        font-size: 14px;
    }
    .profilePicture3 {
        width: 3vw;
        height: 3vw;
        font-size: 14px;
    }

    .lastMessage {
        padding-left: 4.8vw !important;
    }

    .chat-message-list {
        height: 81vh;
    }
}



@media only screen and (max-width: 990px) {
    .profilePicture {
        width: 7vw;
        height: 7vw;
    }

    .profilePicture2 {
        width: 7vw;
        height: 7vw;
    }
    .profilePicture3 {
        width: 7vw;
        height: 7vw;
    }

    .lastMessage {
        padding-left: 11.2vw !important;
    }
}

@media only screen and (max-width: 998px) {
    .profilePicture {
        width: 5vw;
        height: 5vw;
        font-size: 15px;
    }

    .profilePicture2 {
        width: 5vw;
        height: 5vw;
        font-size: 15px;
    }
    .profilePicture3 {
        width: 5vw;
        height: 5vw;
        font-size: 15px;
    }

    .lastMessage {
        padding-left: 6.3vw !important;
    }
}


@media only screen and (max-width: 630px) {
    .profilePicture {
        width: 5vw;
        height: 5vw;
        font-size: 12px;
    }

    .profilePicture2 {
        width: 5vw;
        height: 5vw;
        font-size: 12px;
    }

    .profilePicture3 {
        width: 5vw;
        height: 5vw;
        font-size: 12px;
    }

    .lastMessage {
        padding-left: 8.3vw !important;
    }
}


@media only screen and (max-width: 500px) {
    .profilePicture {
        width: 6vw;
        height: 6vw;
    }

    .profilePicture2 {
        width: 6vw;
        height: 6vw;
    }
    .profilePicture3 {
        width: 6vw;
        height: 6vw;
    }

    .lastMessage {
        padding-left: 9vw !important;
    }
}

    
@media only screen and (max-width: 421px) {
    .profilePicture {
        width: 8vw;
        height: 8vw;
        font-size: 12px;
    }

    .profilePicture2 {
        width: 8vw;
        height: 8vw;
        font-size: 12px;
    }
    .profilePicture3 {
        width: 8vw;
        height: 8vw;
        font-size: 12px;
    }

    .lastMessage {
        padding-left: 11.7vw !important;
    }
}


@media only screen and (max-width: 280px) {
    .profilePicture {
        width: 9vw;
        height: 9vw;
    }

    .profilePicture2 {
        width: 9vw;
        height: 9vw;
    }
    .profilePicture3 {
        width: 9vw;
        height: 9vw;
    }

    .lastMessage {
        padding-left: 15vw !important;
    }

}
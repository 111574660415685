
.bottom-sideBar{
   display: none;
}

.profile{
    cursor: pointer;
}

@media only screen and (max-width: 990px) {

    .bottom-sideBar {
        background-color: #2e2e2e;
        border-right: 1px solid #2e2e2e;
        color: cyan;
        display: flex;
        justify-content: space-between;
        z-index: 1;
        border-top: 1px solid #2e2e2e;
        bottom: 0;
        height: 60px;
        min-height: auto;
        min-width: 100%;
        position: fixed;
        align-items: center;
        padding: 0px 15px;
    }

}





.main1 {
    -webkit-backdrop-filter: blur(7px);
    backdrop-filter: blur(7px);
    background-color: hsla(0, 0%, 100%, .05);
    border-bottom: 1px solid #eaeaf1;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    padding: 25px; 
}


@font-face {
    font-family: 'numansregular';
    src: url('../../fonts/numans-regular-webfont.woff') format('woff2'),
        url('../../fonts/numans-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

.backgroundImageM {
    background-image: url("../../bg.png");
    width: 80%;
    background-color: #f2f2f2;
    transition: all .4s;
    height: 100vh;
    overflow-x: hidden;
}

.innerContent {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
}

.welcome-Data {
    color: rgba(78, 172, 109, 1);

}

.welcome-head {
    color: #495057;
    font-family: 'numansregular';
    font-weight: 600;
    font-size: 21px;
    margin: 0px 0px 8px;
    margin-bottom: 0.6rem;

}

header {
    position: sticky;
    top: 0;
}

#messageBottom {
    height: 80vh;
}



footer {
    position: sticky;
    bottom: 0px;
    padding: 18px 0px;
    background: #f2f2f2;
    border-top: 1px solid #eaeaf1;
}

.welcome-para {
    color: #495057;
    width: 40%;
    text-align: center;
    font-size: 15px;
}
.activeshow{
    font-size: 15px;
    margin-top: 0px;
    color:green;
}


.user-name-header{
    font-weight: 500;
     text-transform: capitalize;
}

.started-btn {
    background-color: rgba(78, 172, 109, .9) !important;
    color: white !important;
    font-size: 15px !important;
    padding: 8px 16px !important;
}

.avatar-title {
    align-items: center;
    background-color: rgba(78, 172, 109, 0.23);
    color: #fff;
    display: flex;
    font-weight: 500;
    height: 100%;
    justify-content: center;
    height: 6rem;
    width: 6rem;
    margin-bottom: 1.5rem !important;

}
.form-control{
    line-height: 2 !important;
    width: 48vw;
}
.chat-box{
    width: -webkit-fill-available;
  
}

.footer-form{
    margin:  0vw 2vw;
}

.sendMessage-btn{
    object-fit: cover;
    width: 55px;
    border: none;
    background-color: yellowgreen;
    border-radius: 6px;
    color: white;
}

.myClass {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    align-items: end;
}

.message-body {
    display: inline;
    margin-right: 0.5vw;
    justify-content: end;
    align-items: end;
    max-width: 41%;
    height: auto;
    background-color: rgba(78, 172, 109, 0.23);
    padding: 6px 15px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
    color: black;
    word-wrap: break-word;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}

.message-main {
    max-width: 41%;
    justify-content: end;
    align-items: end;
    padding: 6px 15px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
    color: black;
    word-wrap: break-word;
    width: fit-content;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;

}

.myClasstime {
    text-align: start;
    margin-bottom: 15px;
    margin-right: 0.5vw;
    font-size: 13px;
}

.myClasstime2 {
    text-align: start;
    margin-bottom: 15px;
    margin-left: 3.5vw;
    font-size: 13px;
}
.backarrow{
    display: none;
}

@media only screen and (max-width: 990px) {

    .sidebar {
        display: none;
    }
    .backarrow{
        display: block !important;
    }

    .backgroundImageM {
        width: 100%;
    }
    .sendMessage-btn{
        object-fit: cover;
        width: 40px;
        border: none;
        background-color: yellowgreen;
        border-radius: 6px;
        color: white;
    }
    .message-body {
        display: inline;
        margin-right: 0.5vw;
        justify-content: end;
        align-items: end;
        max-width: 85% !important;
        height: auto;
        background-color: rgba(78, 172, 109, 0.23);
        padding: 6px 15px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -o-border-radius: 5px;
        border-radius: 5px;
        color: black;
        word-wrap: break-word;
        box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    }
    .message-main {
        max-width: 80%;
        justify-content: end;
        align-items: end;
        padding: 6px 15px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -o-border-radius: 5px;
        border-radius: 5px;
        color: black;
        word-wrap: break-word;
        width: fit-content;
        box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    
    }
}
@font-face {
    font-family: 'numansregular';
    src: url('../../fonts/numans-regular-webfont.woff') format('woff2'),
        url('../../fonts/numans-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

.backgroundImage {
    background-image: url("../../bg.png");
    width: 80%;
    background-color: #f2f2f2;
    position: relative;
    transition: all .4s;
}

.innerContent {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
}

.welcome-Data {
    color: rgba(78, 172, 109, 1);

}

.welcome-head {
    color: #495057;
    font-family: 'numansregular';
    font-weight: 600;
    font-size: 21px;
    margin: 0px 0px 8px;
    margin-bottom: 0.6rem;

}

.welcome-para {
    color: #495057;
    width: 40%;
    text-align: center;
    font-size: 15px;
}

.started-btn {
    background-color: rgba(78, 172, 109, .9) !important;
    color: white !important;
    font-size: 15px !important;
    padding: 8px 16px !important;
}

.avatar-title {
    align-items: center;
    background-color: rgba(78, 172, 109, 0.23);
    color: #fff;
    display: flex;
    font-weight: 500;
    height: 100%;
    justify-content: center;
    height: 6rem;
    width: 6rem;
    margin-bottom: 1.5rem !important;

}

@media only screen and (max-width: 990px) {
    .backgroundImage {
        display: none;
    }

    .chatmain {
        width: 100% !important;
        height: 100% !important;
    }

    .sidebar {
        display: none;
    }

}


.sidebar {
    background-color: #2e2e2e;
    border-right: 1px solid #2e2e2e;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    height: 100vh;
    max-width: 75px;
    min-height: 570px;
    min-width: 85px;
    z-index: 9;
}

.sidebar-data{
  list-style: none;
   font-size: 28px ;
}

li {
    padding: 14px 0px;

}

.profile {
    cursor: pointer;
}

.sidebar ul li:hover {
    transform: scale(1.1);
}